import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { extend } from '@thd-nucleus/data-sources';
import {
  ProductPod,
  PodSticker,
  ProductATC,
  ProductRating,
  ProductSample,
  ProductServiceAddOns,
  ProductIdentifier, ProductBadge, ProductSponsored,
  ProductImage, PodFooter, PodSection, ProductCompare,
  ProductCustomOptions, PodSpacer, BundleDataModel
} from '@thd-olt-component-react/product-pod';
import { Heart } from '@one-thd/sui-icons';
import { IconButton } from '@one-thd/sui-atomic-components';
import { ProductSeeSimilarItems } from '@thd-olt-component-react/product-pod-group';
import { FulfillmentPodShipping, FulfillmentPodStore } from '@thd-olt-component-react/fulfillment';
import { SuperSkuMiniSwatches } from '@thd-olt-component-react/super-sku';
import { Price, PriceClearanceDataModel } from '@thd-olt-component-react/price';
import { ProductHeader } from '@thd-olt-component-react/product-details';
import { AddToList } from '@thd-olt-component-react/add-to-list';
import { ProductQuantity } from '@thd-olt-component-react/quantity';
import { NonBuyableQuoteCenter } from '@thd-olt-component-react/non-buyable-quote-center';
import { OrderSample } from '@thd-olt-component-react/order-sample';
import { useTheme } from '@thd-olt-component-react/theme-provider';

export const CustomProductPod = ({
  itemId,
  storeId,
  position,
  onHover,
  hoverDelay,
  onCompare,
  onSelectedForCompare,
  samplesLoading,
  selectedForCompare,
  itemRecsIndex,
  hasInStoreFilter,
  parent,
  impProductPodData,
  showProjectQuantity,
  hasSameDayFilter
}) => {
  const superSkuChangedItemIdsRef = useRef([]);
  const theme = useTheme(CustomProductPod);
  const { productIdentifierTypes, showProductQuantity, showAddToListButton } = theme.props;
  return (
    <ProductPod
      itemId={itemId}
      onHover={onHover}
      hoverDelay={hoverDelay}
      storeId={storeId}
      analyticsData={{ parent, position }}
      analyticImpressionData={impProductPodData}
      render={(pod) => (
        <>
          <div className="sui-flex sui-justify-between sui-mb-2">
            <PodSection noPadding>
              <ProductBadge itemId={pod.itemId} storeId={storeId} />
              <ProductSponsored itemId={pod.itemId} position={position} />
            </PodSection>
            {
              !showAddToListButton
              && (
                <PodSpacer padding={['TOP', 'RIGHT']}>
                  <AddToList
                    itemId={pod.itemId}
                    showIconButton
                  />
                </PodSpacer>
              )
            }
          </div>
          <ProductImage
            itemId={pod.itemId}
            showSecondaryImage={pod.showSecondaryImage}
          />
          <PodSection columnAlign alignTop>
            <ProductCustomOptions itemId={pod.itemId} />
            <ProductSample itemId={pod.itemId} />
            <SuperSkuMiniSwatches
              itemId={pod.itemId}
              onChange={(clickData) => {
                if (!superSkuChangedItemIdsRef.current.includes(clickData.itemId)) {
                  superSkuChangedItemIdsRef.current = [...superSkuChangedItemIdsRef.current, clickData.itemId];
                }
                pod.onChange(clickData);
              }}
              onHover={pod.onSSKUHover}
              componentImpressionData={
                {
                  name: 'ProductPod',
                  component: 'ProductPod',
                  position: position + 1,
                  type: 'product'
                }
              }
              isImpressionsRequired
            />
            <ProductServiceAddOns itemId={pod.itemId} />
            <PodSpacer minHeight="60px" disable={pod.productSubType === 'HDQC'}>
              <Price
                itemId={pod.itemId}
                large={false}
                storeId={storeId}
                displayEachUom={false}
                showProjectPrice
                onSavingsCenterToggle={pod.onSavingsCenterToggle}
                hideForProductTypes={['HDQC']}
              />
            </PodSpacer>
            <ProductRating itemId={pod.itemId} />
            <PodSpacer minHeight="21px">
              {
                productIdentifierTypes.map((type) => <ProductIdentifier itemId={pod.itemId} type={type} />)
              }
            </PodSpacer>
            <PodSpacer padding={['TOP', 'BOTTOM']}>
              <ProductHeader
                brand="above"
                itemId={pod.itemId}
                brandTitleMaxLine={4}
                disableShopThisCollection
              />
            </PodSpacer>
          </PodSection>
          <PodFooter>
            <PodSpacer
              minHeight="124px"
              disable={pod.productSubType === 'HDQC' || pod.productSubType === 'SFI'}
            >
              <FulfillmentPodStore itemId={pod.itemId} storeId={storeId} hideForChooseYourOptions />
              <FulfillmentPodShipping itemId={pod.itemId} storeId={storeId} hasSameDayFilter={hasSameDayFilter} />
            </PodSpacer>
            <PodSpacer padding={['TOP', 'BOTTOM']} minHeight="4.8rem">
              <div className="sui-flex sui-justify-center">
                <OrderSample
                  disableSampleCall={
                    samplesLoading || (pod.itemId !== itemId && !superSkuChangedItemIdsRef.current.includes(pod.itemId))
                  }
                  itemId={pod.itemId}
                  link
                  showTitle={false}
                />
              </div>
            </PodSpacer>
            {
              showProductQuantity
              && (
                <PodSpacer minHeight="5rem" padding={['TOP', 'BOTTOM']}>
                  <ProductQuantity
                    itemId={pod.itemId}
                    onQuantityChange={pod.onQuantityUpdate}
                    mediumWidth
                    wideView
                    noRightMargin
                  />
                </PodSpacer>
              )
            }
            <PodSpacer padding={['TOP']}>
              <ProductATC
                itemId={pod.itemId}
                storeId={storeId}
                checkGeneric
                outline
                quantity={pod.quantity}
                hasInStoreFilter={hasInStoreFilter}
                showProjectQuantity={showProjectQuantity}
              />
            </PodSpacer>
            <NonBuyableQuoteCenter itemId={pod.itemId} checkForProductSubType={['HDQC']} />
            <ProductSeeSimilarItems
              itemId={pod.itemId}
              position={itemRecsIndex}
              productPodRef={pod.ref}
              storeId={storeId}
            />
            {
              showAddToListButton
              && (
                <PodSpacer padding={['TOP', 'BOTTOM']}>
                  <AddToList itemId={pod.itemId} quantity={pod.quantity} />
                </PodSpacer>
              )
            }
            <ProductCompare
              itemId={pod.itemId}
              onSelectedForCompare={onSelectedForCompare}
              onCompare={onCompare}
              selectedForCompare={selectedForCompare}
            />
          </PodFooter>
        </>
      )}
    />
  );
};

CustomProductPod.displayName = 'CustomProductPod';

CustomProductPod.themeProps = {
  productIdentifierTypes: PropTypes.arrayOf(PropTypes.string),
  showProductQuantity: PropTypes.bool,
  showAddToListButton: PropTypes.bool
};

CustomProductPod.defaultThemeProps = {
  productIdentifierTypes: [
    'model'
  ],
  showProductQuantity: false,
  showAddToListButton: false
};

CustomProductPod.propTypes = {
  itemId: PropTypes.string.isRequired,
  storeId: PropTypes.string.isRequired,
  position: PropTypes.number.isRequired,
  onHover: PropTypes.func.isRequired,
  hoverDelay: PropTypes.number.isRequired,
  onSelectedForCompare: PropTypes.func.isRequired,
  onCompare: PropTypes.func.isRequired,
  itemRecsIndex: PropTypes.number.isRequired,
  samplesLoading: PropTypes.bool.isRequired,
  selectedForCompare: PropTypes.arrayOf(PropTypes.string).isRequired,
  hasInStoreFilter: PropTypes.bool.isRequired,
  impProductPodData: PropTypes.shape({}),
  parent: PropTypes.string,
  showProjectQuantity: PropTypes.bool,
  hasSameDayFilter: PropTypes.bool
};

CustomProductPod.defaultProps = {
  impProductPodData: null,
  parent: 'plp',
  showProjectQuantity: false,
  hasSameDayFilter: false
};

CustomProductPod.dataModel = extend(
  ProductPod,
  ProductImage,
  ProductServiceAddOns,
  AddToList,
  Price,
  SuperSkuMiniSwatches,
  ProductATC,
  ProductRating,
  ProductCompare,
  ProductSample,
  ProductHeader,
  ProductIdentifier,
  ProductBadge,
  ProductSponsored,
  FulfillmentPodStore,
  FulfillmentPodShipping,
  ProductCustomOptions,
  ProductSeeSimilarItems,
  NonBuyableQuoteCenter,
  OrderSample,
  PriceClearanceDataModel,
  BundleDataModel
);
